import instanceOf from "../instanceof";
import render from "../render";
import Validator from "../validator";
import OneOf from "./OneOf";

export default {
  match(type, spec) {
    return [].concat(type).includes("object") || spec.properties;
  },
  default(spec) {
    if (spec.default && typeof spec.default === "object") {
      return spec.default;
    }
    if (spec.oneOf) {
      return instanceOf(spec.oneOf[0]);
    }
    const instance = {};
    for (const [key, value] of Object.entries(spec.properties || {})) {
      instance[key] = instanceOf(value);
    }
    return instance;
  },
  validate(spec, value, required, callback) {
    if (required && !value) {
      return false;
    }
    if (Object.prototype.toString.call(value) !== "[object Object]") {
      return false;
    }
    value = value || {};
    // oneOf 情况下，校验 value 是否满足 oneOf 中的一项
    if (spec.oneOf) {
      for (const item of spec.oneOf) {
        if (Validator.validate(item, value, required, callback)) {
          return true;
        }
      }
      return false;
    }
    // properties 情况下，分别校验各属性的值是否满足条件
    // 为实现提交表单前高亮所有不合法输入，需要校验全部属性
    // every 方法返回 false 后会立即停止遍历，因此先 map 后 every
    if (spec.properties) {
      const requiredProps = spec.required || [];
      return Object.entries(spec.properties)
        .map(([prop, subSpec]) => {
          return Validator.validate(
            subSpec,
            value[prop],
            requiredProps.includes(prop),
            callback
          );
        })
        .every(Boolean);
    }
    return true;
  },
  render(h, renderOpts) {
    const spec = renderOpts.spec || {};
    const validator = renderOpts.validator;
    // render object by oneOf
    if (spec.oneOf) {
      return (
        <OneOf
          spec={spec}
          required={renderOpts.required}
          value={renderOpts.modelValue}
          validator={validator}
          onInput={renderOpts.updateModel}
        />
      );
    }
    // render object by properties
    const parentModelValue = renderOpts.modelValue || {};
    const requiredProps = spec.required || [];
    // flex 布局的 order 样式保障了控件显示顺序
    // 但通过键盘 tab 导航时，聚焦的元素顺序还是乱的，因此再排序一下属性
    return Object.entries(spec.properties || {})
      .sort((propA, propB) => {
        const specA = propA[1] || {};
        const specB = propB[1] || {};
        const orderA = "order" in specA ? specA.order : Infinity;
        const orderB = "order" in specB ? specB.order : Infinity;
        return orderA - orderB;
      })
      .map(([prop, propSpec]) => {
        propSpec.title = propSpec.title || prop;
        const required = requiredProps.includes(prop);
        const opts = {
          modelValue: parentModelValue[prop],
          // 更新 model 时顺便校验该值是否合法
          updateModel: val => {
            parentModelValue[prop] = val;
            validator.validate(propSpec, val, required);
          },
          spec: propSpec,
          validator,
          required
        };
        return render(h, opts);
      });
  }
};
