import getAlienUI from "./ui-alien";

export default {
  match(type) {
    return [].concat(type).includes("string");
  },
  default(spec) {
    return "default" in spec ? String(spec.default) : "";
  },
  validate(spec, value, required) {
    spec = spec || {};
    if (required && !value) {
      return false;
    }
    if (spec.enum) {
      return spec.enum.includes(value);
    }
    if (spec.oneOf) {
      return spec.oneOf.some(item => item.const == value);
    }
    return true;
  },
  render(h, renderOpts) {
    const spec = renderOpts.spec || {};
    const validateStatus = renderOpts.validator.isValid(spec)
      ? "success"
      : "error";
    const AlienUI = getAlienUI(spec.uiType);
    if (AlienUI) {
      return (
        <AlienUI
          render-opts={renderOpts}
          validate-status={validateStatus}
          style={{ order: spec.order }}
        />
      );
    }
    let child = null;
    if (spec.enum) {
      // enum 里面只有一项时自动选上，无需渲染控件
      if (spec.enum.length === 1) {
        renderOpts.updateModel(spec.enum[0]);
        return null;
      }
      // 单选 enum 格式{ type: "string", enum: ["hello", "world"] }
      // 注意 <i-select-option /> 的 key、value、label(slot)一个不能少...不然出 bug 都不知道哪里找...
      child = (
        <a-select
          value={renderOpts.modelValue}
          onSelect={renderOpts.updateModel}
        >
          {spec.enum.map((option, index) => (
            <a-select-option key={index} value={option}>
              {option}
            </a-select-option>
          ))}
        </a-select>
      );
    } else if (spec.oneOf) {
      // oneOf 里面只有一项时自动选上，无需渲染控件
      if (spec.oneOf.length === 1) {
        renderOpts.updateModel(spec.oneOf[0].const);
        return null;
      }
      // 或者 oneOf 格式，支持通过 title 定义选项的中文名称
      // { type: "string", oneOf: [{ const: "hello", title: "你好" }, { const: "world", title: "世界" }] }
      child = (
        <a-select
          value={renderOpts.modelValue}
          onSelect={renderOpts.updateModel}
        >
          {spec.oneOf.map((option, index) => (
            <a-select-option
              key={index}
              value={option.const}
              title={option.description}
            >
              {option.title || option.const}
            </a-select-option>
          ))}
        </a-select>
      );
    } else {
      // 输入型字符串
      const InputType = spec.multiline
        ? "a-textarea"
        : spec.airbyte_secret || spec.dataops_secret
        ? "a-input-password"
        : "a-input";
      const placeholder = spec.examples
        ? `e.g. ${[].concat(spec.examples || []).join(", ")}`
        : null;
      child = (
        <InputType
          rows="3"
          value={renderOpts.modelValue}
          placeholder={placeholder}
          visibilityToggle={false}
          onInput={evt => renderOpts.updateModel(evt.target.value)}
        />
      );
    }
    return (
      <a-form-item
        label={spec.title}
        style={{ order: spec.order }}
        required={renderOpts.required}
        validate-status={validateStatus}
      >
        {child}
        {spec.description ? (
          <span
            slot="help"
            {...{ domProps: { innerHTML: spec.description } }}
          ></span>
        ) : null}
      </a-form-item>
    );
  }
};
