import Types from "./types";

export default function instanceOf(spec) {
  spec = spec || {};
  const fieldType = spec.type;
  for (const item of Types) {
    if (item.match(fieldType, spec)) {
      return item.default(spec);
    }
  }
  return null;
}
