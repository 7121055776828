<template>
  <InlineFormItem
    class="field-oneof"
    :spec="spec"
    :required="required"
    :style="{
      border: 'dashed 2px #bae7ff',
      borderRadius: '8px',
      padding: '16px'
    }"
    :field-styles="{ marginTop: '-30px', background: '#fff', padding: '0 6px' }"
    :label-styles="{ fontWeight: 'bold' }"
  >
    <a-select
      v-model="oneOfIndex"
      style="width: 300px"
      placeholder="清选择"
      @select="onSelectSchema"
    >
      <a-select-option
        v-for="(item, index) in oneOfOptions"
        :key="index"
        :value="index"
        :title="item | optionTitle"
      >
        {{ item.title }}
      </a-select-option>
    </a-select>
    <!-- 空 div 作为 gutter -->
    <div slot="append" style="height: 40px"></div>
    <OneOfChild
      slot="append"
      :spec="oneOfSpec"
      :required="required"
      :value="value"
      :validator="validator"
      @input="$emit('input', $event)"
    />
  </InlineFormItem>
</template>

<script>
import intersection from "lodash/intersection";
import InlineFormItem from "../inline-form-item";
import OneOfChild from "./OneOfChild";
import instanceOf from "../instanceof";

export default {
  components: { InlineFormItem, OneOfChild },
  props: {
    spec: Object,
    required: Boolean,
    validator: Object,
    value: Object
  },
  data() {
    return {
      oneOfIndex: null
    };
  },
  computed: {
    oneOfOptions() {
      return this.spec.oneOf || [];
    },
    oneOfSpec() {
      return this.oneOfOptions[this.oneOfIndex] || {};
    }
  },
  filters: {
    optionTitle(option) {
      const res = [option.title];
      if (option.description) {
        res.push(option.description);
      }
      return res.join("\n");
    }
  },
  created() {
    this.resetIndex();
  },
  watch: {
    value() {
      this.resetIndex();
    },
    oneOfOptions() {
      this.resetIndex();
    }
  },
  methods: {
    resetIndex() {
      const modelValue = this.value || {};
      // schema.const 可定义属性的常量值
      // schema.enum[0] 等价于 schema.const，有些大傻子喜欢用 enum :)
      const getConstValue = schema => {
        return schema && (schema.const || (schema.enum && schema.enum[0]));
      };
      // 从 required 过滤出包含常量值的属性列表，并求出交集，得到所有选项的公共属性
      const universalProp = intersection(
        ...this.oneOfOptions.map(item => {
          const properties = item.properties || {};
          const props = Object.keys(properties);
          return props.filter(key => getConstValue(properties[key]));
        })
      )[0];
      if (!universalProp) {
        return;
      }
      const index = this.oneOfOptions.findIndex(item => {
        const schema = item.properties[universalProp] || {};
        if (modelValue[universalProp] === getConstValue(schema)) {
          return true;
        }
        return false;
      });
      this.oneOfIndex = index < 0 ? null : index;
    },
    onSelectSchema(index) {
      const schema = this.oneOfOptions[index];
      this.$emit("input", instanceOf(schema));
    }
  }
};
</script>
