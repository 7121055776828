export function requireScript(code) {
  const module = { exports: {} };
  const fn = new Function(
    "require",
    "module",
    "exports",
    "process",
    "__dirname",
    "__filename",
    code
  );
  const Modules = {
    "dataops-cdk": {
      DataopsStreamBase: class {},
      Logger: {},
      SyncMode: {}
    },
    dayjs: {},
    lodash: {},
    playwright: {},
    axios: {}
  };
  const mockRequire = moduleid => Modules[moduleid] || {};
  fn(mockRequire, module, module.exports, { env: {} });
  return module.exports;
}
