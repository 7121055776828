<template>
  <InlineFormItem
    :spec="renderOpts.spec || {}"
    :required="renderOpts.required"
    :validate-status="validateStatus"
    @mouseenter.native="onFocus"
  >
    <span slot="label" slot-scope="defaultTitle">
      {{ defaultTitle }}
      <a-tag v-if="isAuthed" color="green">
        <a-icon type="check-circle" theme="filled" /> 已授权
      </a-tag>
    </span>
    <a-button
      icon="safety-certificate"
      type="primary"
      :disabled="!isMatched"
      :loading="loading || loadingUrl"
      @click="onAuth"
    >
      {{ isAuthed ? "重新授权" : "获取授权信息" }}
    </a-button>
  </InlineFormItem>
</template>

<script>
import { getOAuthToken, getOAuthPageInfo } from "@/api/connections";
import InlineFormItem from "../inline-form-item";

export default {
  components: { InlineFormItem },
  inject: {
    jsonSchemaRoot: { default: {} }
  },
  props: {
    renderOpts: Object,
    validateStatus: String
  },
  data() {
    return { loading: false, loadingUrl: false };
  },
  computed: {
    uiOptions() {
      const spec = this.renderOpts.spec || {};
      return spec.uiOptions || {};
    },
    isAuthed() {
      return Boolean(this.renderOpts.modelValue);
    },
    // 检测授权按钮是否可用
    isMatched() {
      const requiredConfig = [].concat(this.uiOptions.required || []);
      // config 对象用于 eval
      // eslint-disable-next-line no-unused-vars
      const config = this.jsonSchemaRoot.model || {};
      return requiredConfig.every(item => {
        try {
          return Boolean(eval(item));
        } catch {
          return true;
        }
      });
    }
  },
  created() {
    window.addEventListener("message", this.onMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },
  methods: {
    async getOAuthURL() {
      // config 和 uiOptions 对象用于 eval
      // eslint-disable-next-line no-unused-vars
      const config = this.jsonSchemaRoot.model || {};
      const uiOptions = this.uiOptions;
      try {
        // 如果 oauth_url 是空的，那就去调用后台接口获取一下，这是为了规避后台的一些缓存问题
        if (!uiOptions.oauth_url) {
          this.loadingUrl = true;
          uiOptions.oauth_url = await getOAuthPageInfo(uiOptions.app_type).then(
            async res => {
              const data = res.data.data || {};
              return data.oauthPageUrl || "";
            }
          );
          this.loadingUrl = false;
        }
        return eval(uiOptions.oauth_url);
      } catch {
        return uiOptions.oauth_url;
      }
    },
    async onAuth() {
      this.loading = true;
      const screen = window.screen;
      const win = window.open(
        await this.getOAuthURL(),
        "oauth",
        `popup=yes,
        location=no,
        menubar=no,
        status=no,
        width=${screen.width * 0.8},
        height=${screen.height * 0.8},
        left=${screen.width * 0.1},
        top=${screen.height * 0.1}`
      );
      this.winref = win;
      setTimeout(this.checkWinStats, 1000);
    },
    onFocus() {
      const win = this.winref;
      if (win) {
        this.loading = true;
        win.focus();
      } else {
        this.loading = false;
      }
    },
    async onMessage(evt) {
      const evtData = evt.data || {};
      if (evtData.source !== "oauth-redirect") {
        return;
      }
      // 关闭弹窗
      if (this.winref) {
        this.winref.close();
        this.winref = null;
      }
      this.loading = false;
      try {
        // 根据 auth_code 换取 access_token 等信息
        const authUrl = new URL(evtData.payload, location.origin);
        const tokenParams = authUrl.searchParams;
        if (!tokenParams.has("auth_code")) {
          tokenParams.append("auth_code", tokenParams.get(this.uiOptions.key));
        }
        const { data: res } = await getOAuthToken(
          this.uiOptions.app_type,
          tokenParams
        );
        if (res.code != 200) {
          throw res;
        }
        const message = JSON.stringify(res.data);
        this.renderOpts.updateModel(message);
      } catch (err) {
        this.$message.error(err.msg || "授权失败");
      }
    },
    checkWinStats() {
      const win = this.winref;
      if (!win || win.closed) {
        this.loading = false;
        this.winref = null;
        return;
      }
      setTimeout(this.checkWinStats, 200);
    }
  }
};
</script>
