<script>
import OAuth from "./OAuth";

export default {
  mixins: [OAuth],
  methods: {
    getOAuthURL() {
      return "https://open.oceanengine.com/audit/oauth.html?app_id=1725338521003071&state=your_custom_params&scope=%5B12100000%2C12140000%2C130%2C4%2C112%2C11101100%2C14%2C12150000%2C110%2C120%2C122%2C30%5D&material_auth=1&redirect_uri=http%3A%2F%2Fwww.eiplatform.cn%2Foauth-redirect.html&rid=m4sdxv3rdg8";
    }
  }
};
</script>
