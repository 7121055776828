<template>
  <a-form-item
    :label="renderOpts.spec.title"
    :required="renderOpts.required"
    :validate-status="validateStatus"
  >
    <a-textarea
      read-only
      placeholder="请上传脚本..."
      style="cursor: pointer"
      :value="renderOpts.modelValue"
      :rows="8"
      @click.native="onClick"
    />
    <JSONSchema
      v-if="scriptSpec"
      style="margin-top: 24px"
      :specification="scriptSpec"
      v-model="rootConfig.automatorConfig"
    />
  </a-form-item>
</template>

<script>
import { requireScript } from "./utils";

export default {
  name: "upload-script",
  // 只能把 JSONSchema 组件定义成异步组件，否则子组件使用父组件会导致 vue 报错
  components: { JSONSchema: () => import("@/components/json-schema-ui") },
  inject: {
    jsonSchemaRoot: { default: {} }
  },
  props: {
    renderOpts: Object,
    validateStatus: String
  },
  data() {
    return {
      scriptSpec: null
    };
  },
  computed: {
    rootConfig() {
      return this.jsonSchemaRoot.model || {};
    }
  },
  created() {
    this.initScriptSpec();
  },
  watch: {
    "renderOpts.modelValue"() {
      this.initScriptSpec();
    }
  },
  methods: {
    pickFile() {
      return new Promise(resolve => {
        const inputElm = document.createElement("input");
        inputElm.accept = ".js";
        inputElm.type = "file";
        inputElm.onchange = () => {
          resolve(inputElm.files[0]);
        };
        inputElm.click();
      });
    },
    readFile(file) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsText(file);
      });
    },
    async onClick() {
      const file = await this.pickFile();
      const script = await this.readFile(file);
      this.renderOpts.updateModel(script);
    },
    initScriptSpec() {
      try {
        const exports = requireScript(this.renderOpts.modelValue);
        if (exports && typeof exports.spec === "function") {
          const spec = exports.spec();
          this.scriptSpec = spec.connectionSpecification;
          console.log(spec);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
