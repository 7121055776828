import object from "./object";
import string from "./string";
import number from "./number";
import boolean from "./boolean";
import array from "./array";

export default [
  // spec 中定义了 const 关键字时，无需渲染控件
  {
    match(type, spec) {
      return spec && "const" in spec;
    },
    default(spec) {
      return spec ? spec.const : null;
    },
    validate() {
      return true;
    },
    render() {
      return null;
    }
  },
  object,
  string,
  number,
  boolean,
  array,
  // null
  {
    match(type) {
      return type === "null";
    },
    default() {
      return null;
    },
    validate() {
      return true;
    },
    render() {
      return null;
    }
  }
];
