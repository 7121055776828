import InlineFormItem from "../inline-form-item";

export default {
  match(type) {
    return [].concat(type).includes("boolean");
  },
  default(spec) {
    return !!spec.default;
  },
  validate() {
    // 布尔类型的基本不用校验了，怎么着都是对的
    return true;
  },
  render(h, renderOpts) {
    const spec = renderOpts.spec || {};
    return (
      <InlineFormItem spec={spec} required={renderOpts.required}>
        <a-switch
          checked={renderOpts.modelValue}
          onChange={renderOpts.updateModel}
        />
      </InlineFormItem>
    );
  }
};
