export function sortConnectors(list = []) {
  return list.sort((a, b) => {
    if (a.createTime > b.createTime) {
      return 1;
    }
    if (a.createTime < b.createTime) {
      return -1;
    }
    return 0;
  });
}

export { sortConnectors as sortConnections };

export function sortDefinitions(definitions) {
  return definitions.sort((a, b) => {
    const orderA = typeof a.orderNo == "number" ? a.orderNo : Infinity;
    const orderB = typeof b.orderNo == "number" ? b.orderNo : Infinity;
    return orderA - orderB;
  });
}

export function normalizeConnections(connections, mergedConnections = []) {
  const mergedConnectionMap = mergedConnections.reduce((map, item) => {
    map[item.id] = item;
    return map;
  }, {});
  for (const item of connections) {
    const mergedConnection = mergedConnectionMap[item.id];
    // 连接器 mouseenter 时，此连接器和相关连接会设为 active 状态
    // isActive 用于辅助实现 hover 时隐藏无关连接
    item.isActive = mergedConnection ? mergedConnection.isActive : true;
    // status 标识连接是否启用，非手动同步的连接支持启用/禁用
    // 可取值 ["active", "inactive", "deprecated"]
    item.status = item.status || "inactive";
    // isSwitching 标识启用/禁用连接时的「正在请求状态」
    // 用于连接列表的 <a-switch /> loading 状态
    item.isSwitching = false;
    // isSyncing 标识连接是否正在同步，运行中的连接其「立即同步」按钮会禁用
    item.isSyncing = mergedConnection ? mergedConnection.isSyncing : false;
    // latestSyncJobCreatedAt 最近一次连接同步时间
    item.latestSyncJobCreatedAt = item.latestSyncJobCreatedAt || null;
    // latestSyncJobStatus 最近一次连接同步状态
    // 可取值: ["pending", "running", "incomplete", "failed", "succeeded", "cancelled"]
    item.latestSyncJobStatus = item.latestSyncJobStatus || null;
    // isJobStatusLoading 用于标识此连接的最近任务状态是否正在加载中
    // 目前用在推送任务列表中展示状态 loading 动画
    item.isJobStatusLoading = mergedConnection
      ? mergedConnection.isJobStatusLoading
      : true;
  }
  return connections;
}

export function normalizeConnectors(connectors) {
  for (const item of connectors) {
    item.isActive = true;
  }
  return connectors;
}

export function updateConnectionStatus(connections = [], statusList = []) {
  const statusMap = statusList.reduce((map, item) => {
    map[item.id] = item;
    return map;
  }, {});
  for (const item of connections) {
    const status = statusMap[item.id];
    if (status) {
      item.isSyncing = !!status.isSyncing;
      item.latestSyncJobCreatedAt = status.latestSyncJobCreatedAt || null;
      item.latestSyncJobStatus = status.latestSyncJobStatus || null;
      item.isJobStatusLoading = !statusMap[item.id];
    }
  }
}

export const Types = {
  SOURCE: "source",
  DESTINATION: "destination"
};

export function localeTypes(type) {
  switch (type) {
    case Types.SOURCE:
      return "数据源";
    case Types.DESTINATION:
      return "目标库";
    default:
      return "";
  }
}

export { keepState } from "@/utils/function";
