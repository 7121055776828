<template>
  <InlineFormItem
    :spec="renderOpts.spec || {}"
    :required="renderOpts.required"
    :validate-status="validateStatus"
  >
    <img
      v-if="renderOpts.modelValue"
      class="custom-icon"
      :src="renderOpts.modelValue"
      @click="onClick"
    />
    <div v-else class="placeholder" @click="onClick"></div>
  </InlineFormItem>
</template>

<script>
import * as API from "@/api/common";
import InlineFormItem from "../inline-form-item";

export default {
  components: { InlineFormItem },
  props: {
    renderOpts: Object,
    validateStatus: String
  },
  methods: {
    pickFile() {
      return new Promise(resolve => {
        const inputElm = document.createElement("input");
        inputElm.accept = ".jpg,.png,.svg,.jpeg,.webp";
        inputElm.type = "file";
        inputElm.onchange = () => {
          resolve(inputElm.files[0]);
        };
        inputElm.click();
      });
    },
    async onClick() {
      const file = await this.pickFile();
      if (!file) {
        return;
      }
      if (file.size > 200 * 1024) {
        return this.$message.warning("请选择小于 200k 的图片");
      }
      const { data } = await API.uploadFile(file);
      this.renderOpts.updateModel(data.url);
    }
  }
};
</script>

<style lang="less" scoped>
.custom-icon,
.placeholder {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
}
.placeholder {
  border: dashed 1px #ebebeb;
  position: relative;
}
@gap: 20px;
.placeholder::before {
  content: "";
  position: absolute;
  top: 50%;
  left: @gap;
  right: @gap;
  margin-top: -1px;
  height: 2px;
  background: #ebebeb;
}

.placeholder::after {
  content: "";
  position: absolute;
  left: 50%;
  top: @gap;
  bottom: @gap;
  margin-left: -1px;
  width: 2px;
  background: #ebebeb;
}
</style>
