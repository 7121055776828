import Types from "./types";

function traverse(spec, tracer) {
  if (Array.isArray(spec)) {
    for (const item of spec) {
      traverse(item, tracer);
    }
    return;
  }
  if (typeof spec === "object") {
    if (tracer) {
      tracer(spec);
    }
    for (const value of Object.values(spec)) {
      traverse(value, tracer);
    }
  }
}

export default class Validator {
  constructor(spec) {
    const specMap = new Map();
    const valdationResult = {};
    let counter = 0;
    traverse(spec, specNode => {
      specMap.set(specNode, counter);
      valdationResult[counter] = true;
      counter++;
    });
    this.specMap = specMap;
    this.valdationResult = valdationResult;
  }

  isValid(spec) {
    const specId = this.specMap.get(spec);
    return !!this.valdationResult[specId];
  }

  /**
   * 与静态方法唯一的区别是，实例方法会影响实例中的 valdationResult
   */
  validate(spec, value, required) {
    const specMap = this.specMap;
    const valdationResult = this.valdationResult;
    const callback = (isValid, spec) => {
      const specId = specMap.get(spec);
      valdationResult[specId] = isValid;
    };
    return Validator.validate(spec, value, required, callback);
  }

  /**
   * 校验 value 是否符合 specification
   * @param {Object} spec json-schema specification
   * @param {any} value 表单域的值
   * @param {Boolean} required 是否必填表单域
   * @param {Function} callback 校验完成之后的回调函数，可选。一般用于递归校验，如对象的某个属性仍然是对象。
   */
  static validate(spec, value, required, callback) {
    spec = spec || {};
    let isValid = true;
    const fieldType = spec.type;
    for (const item of Types) {
      if (item.match(fieldType, spec)) {
        isValid = item.validate(spec, value, required, callback);
        break;
      }
    }
    if (callback) {
      callback(isValid, spec, value, required);
    }
    return isValid;
  }
}
