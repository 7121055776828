import each from "lodash/each";
import mergeWith from "lodash/mergeWith";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";

/**
 * 合并 a, b 两个对象中 a 中存在的属性
 * 例如：merge({ x: 1 }, { x: 2, y: 3})
 * => { x: 2 }
 * @param {Object} a object
 * @param {Object} b source
 * @returns {Object}
 */
export default function merge(a, b) {
  const symbol = Symbol.for("invalid prop");
  const merged = mergeWith(a, b, (objVal, srcVal, key, obj) => {
    if (isArray(obj)) {
      return srcVal;
    }
    if (key in obj) {
      return srcVal;
    }
    return symbol;
  });
  function clean(data) {
    return each(data, (value, key, obj) => {
      if (value === symbol) {
        delete obj[key];
      } else if (isObject(value)) {
        clean(value);
      }
    });
  }
  return clean(merged);
}
