export default {
  name: "inline-form-item",
  props: {
    spec: {
      type: Object,
      required: true
    },
    required: Boolean,
    validateStatus: String,
    // 表单项样式（不是最外层结构，最外层结构样式直接通过 style 设置）
    fieldStyles: Object,
    // 表单标签样式
    labelStyles: Object,
    // append 插槽样式
    appendSlotStyles: Object
  },
  render() {
    const spec = this.spec || {};
    const slots = this.$slots;
    const scopedSlots = this.$scopedSlots;
    return (
      <div
        class={[
          "ant-form-item",
          this.validateStatus ? "has-" + this.validateStatus : null,
          // 存在 description 时 加上 .ant-form-item-with-help 类
          spec.description ? "ant-form-item-with-help" : null
        ]}
        style={{ order: spec.order }}
      >
        <div
          style={[
            { display: "flex", alignItems: "flex-start" },
            this.fieldStyles
          ]}
        >
          {/* label */}
          <label
            style={[{ flex: "1 1 0" }, this.labelStyles]}
            class={this.required ? "ant-form-item-required" : ""}
          >
            {scopedSlots.label ? scopedSlots.label(spec.title) : spec.title}
          </label>
          {/* 默认插槽 */}
          {slots.default}
        </div>
        {/* 描述信息 */}
        {spec.description ? (
          <div
            class="ant-form-explain"
            {...{ domProps: { innerHTML: spec.description } }}
          ></div>
        ) : null}
        {/* append 插槽 */}
        {slots.append ? (
          <div style="display: flex; flex-direction: column">
            {slots.append}
          </div>
        ) : null}
      </div>
    );
  }
};
