<script>
import render from "../render";

export default {
  functional: true,
  render(h, context) {
    const props = context.props;
    const opts = {
      modelValue: props.value || {},
      updateModel: context.listeners.input,
      spec: props.spec,
      validator: props.validator,
      required: !!props.required
    };
    return render(h, opts);
  }
};
</script>
