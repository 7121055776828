import Types from "./types";

/**
 * 根据 spec 渲染表单项
 * @param {Function} h vue createElement function
 * @param {Object} renderOpts
 * @param {any} renderOpts.modelValue form-item value
 * @param {Funtion} renderOpts.updateModel 更新 modelValue 方法，updateModel(value)
 * @param {Object} renderOpts.spec 表单对应的 specification
 * @param {Boolean} renderOpts.required 是否必填
 * @returns {VNode} createElement 返回的结果
 */
export default function(h, renderOpts) {
  const spec = renderOpts.spec || {};
  const fieldType = spec.type;
  for (const item of Types) {
    if (item.match(fieldType, spec)) {
      return item.render(h, renderOpts);
    }
  }
  return null;
}
