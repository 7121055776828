export default {
  match(type) {
    return [].concat(type).includes("array");
  },
  default(spec) {
    return "default" in spec ? [].concat(spec.default || []) : [];
  },
  validate(spec, value, required) {
    spec = spec || {};
    const values = [].concat(value);
    const length = values.length;
    // 标记为 required 时，至少选择一项
    if (required && !values.length) {
      return false;
    }
    const minItems = spec.minItems || 0;
    const maxItems = spec.maxItems || Infinity;
    return length >= minItems && length <= maxItems;
  },
  render(h, renderOpts) {
    const spec = renderOpts.spec || {};
    const validateStatus = renderOpts.validator.isValid(spec)
      ? "success"
      : "error";
    return (
      <a-form-item
        label={spec.title}
        style={{ order: spec.order }}
        required={renderOpts.required}
        validate-status={validateStatus}
      >
        <a-select
          mode="tags"
          value={renderOpts.modelValue}
          onChange={renderOpts.updateModel}
        />
        {spec.description ? (
          <span
            slot="help"
            {...{ domProps: { innerHTML: spec.description } }}
          ></span>
        ) : null}
      </a-form-item>
    );
  }
};
