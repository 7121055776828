import OAuth from "./OAuth";
import OAuthOceanEngine from "./OAuthOceanEngine";
import UploadScript from "./UploadScript";
import UploadIcon from "./UploadIcon";

export default function(uiType) {
  if (!uiType) {
    return null;
  }
  switch (uiType) {
    case "oauth-oceanengine":
      return OAuthOceanEngine;
    case "upload-script":
      return UploadScript;
    case "upload-icon":
      return UploadIcon;
    default:
      return OAuth;
  }
}
