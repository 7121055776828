import instanceOf from "./src/instanceof";
import mergeObject from "./merge-object";
import Validator from "./src/validator";
import render from "./src/render";

export default {
  name: "json-schema",
  props: {
    specification: {
      type: Object,
      required: true
    },
    layout: {
      type: String,
      default: "vertical"
    },
    // any type
    value: null
  },
  provide() {
    return {
      jsonSchemaRoot: this
    };
  },
  data() {
    return {
      model: {},
      validator: new Validator(this.specification)
    };
  },
  created() {
    this.resetModel();
  },
  watch: {
    value(newVal) {
      if (newVal !== this.model) {
        this.resetModel();
      }
    },
    specification(newSpec) {
      this.validator = new Validator(newSpec);
      this.resetModel();
    },
    model(modelValue) {
      this.$emit("input", modelValue);
    }
  },
  methods: {
    resetModel() {
      const instance = instanceOf(this.specification);
      this.model = mergeObject(instance, this.value);
    },
    validate() {
      return this.validator.validate(this.specification, this.model, false);
    }
  },
  render(h) {
    const renderOpts = {
      modelValue: this.model,
      updateModel: newModel => {
        this.model = newModel;
        this.validate();
      },
      spec: this.specification,
      validator: this.validator,
      required: false
    };
    return (
      <a-form layout={this.layout}>
        {this.$slots.header}
        {/* 欺骗浏览器自动填充 */}
        <div style="height: 0; overflow: hidden; position: fixed; left: -999px; top: -999px;">
          <input type="text" />
          <input type="password" />
        </div>
        {render(h, renderOpts)}
        {this.$slots.footer}
      </a-form>
    );
  }
};
