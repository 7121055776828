import InlineFormItem from "../inline-form-item";

export default {
  match(type) {
    const types = [].concat(type);
    return types.includes("number") || types.includes("integer");
  },
  default(spec) {
    return "default" in spec ? Number(spec.default) : null;
  },
  validate(spec, value, required) {
    spec = spec || {};
    if (required && typeof value !== "number") {
      return false;
    }
    return (
      ("minimum" in spec ? value >= spec.minimum : true) &&
      ("exclusiveMinimum" in spec ? value > spec.exclusiveMinimum : true) &&
      ("maximum" in spec ? value <= spec.maximum : true) &&
      ("exclusiveMaximum" in spec ? value <= spec.exclusiveMaximum : true)
    );
  },
  render(h, renderOpts) {
    const spec = renderOpts.spec || {};
    const validateStatus = renderOpts.validator.isValid(spec)
      ? "success"
      : "error";
    return (
      <InlineFormItem
        spec={spec}
        required={renderOpts.required}
        validate-status={validateStatus}
      >
        <a-input-number
          style="width: 140px"
          value={renderOpts.modelValue}
          min={"minimum" in spec ? spec.minimum : void 0}
          max={"maximum" in spec ? spec.maximum : void 0}
          onChange={renderOpts.updateModel}
        />
      </InlineFormItem>
    );
  }
};
